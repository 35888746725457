import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class AppService {
  baseUrl = environment.baseUrl;
  loginUrl = environment.loginUrl;
  constructor(private http: HttpClient) { }

  postService(data: any, url: any): Observable<any> {
    const user = JSON.parse(localStorage.getItem('office_q_uid'));
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.s_token
    });
    return this.http.post(this.baseUrl + url, data, {
      headers: httpHeaders,
      observe: 'response'
    });

  }

  login(user: any, url: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.loginUrl + url, user,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  fileService(data: any, url: any): Observable<any>{
    const user = JSON.parse(localStorage.getItem('office_q_uid'));

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'undefined',
      Authorization: 'Bearer ' + user.s_token
    });
    return this.http.post(this.baseUrl + url, data,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

}
