import { AmazingTimePickerModule } from 'amazing-time-picker';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatRadioModule,
    MatStepperModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatListModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatMenuModule,
    MatTreeModule
} from '@angular/material';
import { Angular2UsefulSwiperModule } from 'angular2-useful-swiper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { UserModalComponent } from './user-modal/user-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { NumbersOnlyDirective } from './directives/number-only/numbers-only.directive';

import { FilterPipe } from './pipes/filter.pipe';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { from } from 'rxjs';
import { AttachedModalComponent } from './attached-modal/attached-modal.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatRadioModule,
        MatStepperModule,
        MatExpansionModule,
        MatAutocompleteModule,
        MatListModule,
        MatCheckboxModule,
        MatDialogModule,
        MatProgressBarModule, MatRadioModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        MatMenuModule,
        CdkStepperModule,
        MatTreeModule,
        AmazingTimePickerModule,
        Angular2UsefulSwiperModule,
        Ng2SearchPipeModule,
    ],
    exports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,

      MatButtonModule,
      MatChipsModule,
      MatFormFieldModule,
      MatIconModule,
      MatInputModule,
      MatPaginatorModule,
      MatRippleModule,
      MatSelectModule,
      MatSortModule,
      MatTableModule,
      MatTabsModule,
      MatSlideToggleModule,
      MatToolbarModule,
      MatDatepickerModule,
      MatTooltipModule,
      MatRadioModule,
      MatStepperModule,
      MatExpansionModule,
      MatAutocompleteModule,
      MatListModule,
      MatCheckboxModule,
      MatDialogModule,
      MatProgressBarModule, MatRadioModule,
      ReactiveFormsModule,
      MatNativeDateModule,
      MatMenuModule,
      CdkStepperModule,
      MatTreeModule,
      AmazingTimePickerModule,
      Angular2UsefulSwiperModule,
      Ng2SearchPipeModule,
      NumbersOnlyDirective,
      PerfectScrollbarModule
    ],
    declarations: [UserModalComponent, ConfirmModalComponent, AttachedModalComponent, NumbersOnlyDirective,  FilterPipe],
    providers: [{
        provide: PERFECT_SCROLLBAR_CONFIG,
        useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }]
})
export class SharedModule {
}
